import React from 'react';
import 'bootstrap';
import { Table } from 'reactstrap';
import { FaAws, FaNode } from 'react-icons/fa';
import { SiDotnet,SiPython,SiAndroid,SiUnity,SiAngularjs,SiApple,SiReact,SiAmazonaws,SiMicrosoftazure,SiLaravel,SiWordpress,SiPhp,SiSpringboot } from 'react-icons/si';
import { IconContext } from "react-icons";
import './home.css';
import introBG from './introBG.mp4';
import introBG2 from './4thBgPic.mp4';
import uiuxPng from "./serviceIcons/uiux.png";
import consultPng from "./serviceIcons/consulting.png";
import devopsPng from "./serviceIcons/devops.png";
import gamePng from "./serviceIcons/gameDev.png";
import mobPng from "./serviceIcons/mobileDev.png";
import shopifyPng from "./serviceIcons/shopify.png";
import webPng from "./serviceIcons/webDev.png";
import wpPng from "./serviceIcons/wordPress.png";

export default function Home(params) {
    return(

        <>
        <div>
            <div id='intro' className='video-background' style={{backgroundAttachment:introBG}}>
            <video className='introBg' autoPlay loop muted>
                    <source src={introBG2} type='video/mp4' />
                    Your browser does not support the video tag.
                </video>
                <div class="overlay"></div>
                <div className='content'>
                    <span>
                        <h3>Your Dream is our Passion</h3>
                    </span>
                    <span>We provide expert technology solutions for your business needs.</span><br />
                    <button className='btn btn-light mt-3'><a href='/contact'>Contact Us</a></button> <br />
                    <h4>OR</h4>
                    <h5>Reach us at <a href="mailto:info@ftscorp.ca">INFO@FTSCORP.CA</a></h5>
                </div>
            </div>



            <div id='services' className='services'>
                <div className='expertise-inner-div'>
                <h3>Our Services</h3>
                <Table borderless responsive>
                    <tbody>
                    <tr>
                        <td>
                            <img src={uiuxPng} className='serviceImg' /> <br />
                        <p>UI & UX Design</p>
                        </td>
                        <td>
                        <img src={wpPng} className='serviceImg' /> <br />
                        <p>WordPress Development</p>
                        </td>
                        <td>
                        <img src={shopifyPng} className='serviceImg' /> <br />
                        <p>Shopify Development</p>
                        </td>
                    </tr>
                    <tr>
                        
                        <td>
                        <img src={mobPng} className='serviceImg' /> <br />
                        <p>Mobile Applications</p>
                        </td>
                        <td>
                        <img src={webPng} className='serviceImg' /> <br />
                        <p>Web Applications</p>
                        </td>
                        <td>
                        <img src={consultPng} className='serviceImg' /> <br />
                        <p>Consultancy Services</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <img src={gamePng} className='serviceImg' /> <br />
                        <p>Games Development</p>
                        </td>
                        <td>
                        <img src={devopsPng} className='serviceImg' /> <br />
                        <p>Cloud & DevOps</p>
                        </td>
                    </tr>
                    </tbody>
                </Table>
                </div>
            </div>

            <div id='expertise' className='expertise'>
                <div className='expertise-inner-div' >
                <h3 style={{justifyItems:'center'}}>Our Expertise</h3>
                <div>
                <Table borderless responsive className='table'>
                    <tbody>
                    <tr>
                        <td>
                        <IconContext.Provider value={{ color: "green", className: "global-class-name", size:"10%" }}>
                                <div>
                                    <FaNode />
                                </div>
                            </IconContext.Provider>
                        </td>
                        <td>
                        <IconContext.Provider value={{ color: "purple", className: "global-class-name", size:"10%" }}>
                                <div>
                                    <SiDotnet />
                                </div>
                            </IconContext.Provider>
                        </td>
                        <td>
                        <IconContext.Provider value={{ color: "blue", className: "global-class-name", size:"10%" }}>
                                <div>
                                    <SiPython />
                                </div>
                            </IconContext.Provider>
                        </td>
                        <td>
                        <IconContext.Provider value={{ color: "lime", className: "global-class-name", size:"10%" }}>
                                <div>
                                    <SiAndroid />
                                </div>
                            </IconContext.Provider>
                        </td>
                    </tr>
                    <tr id='row2'>
                        <td>
                        <IconContext.Provider value={{ className: "global-class-name", size:"10%" }}>
                                <div>
                                    <SiUnity />
                                </div>
                            </IconContext.Provider>
                        </td>
                        <td>
                        <IconContext.Provider value={{ color: "red", className: "global-class-name", size:"10%" }}>
                                <div>
                                    <SiAngularjs />
                                </div>
                            </IconContext.Provider>
                        </td>
                        <td>
                        <IconContext.Provider value={{ className: "global-class-name", size:"10%" }}>
                                <div>
                                    <SiApple />
                                </div>
                            </IconContext.Provider>
                        </td>
                        <td>
                        <IconContext.Provider value={{ color: "lightBlue", className: "global-class-name", size:"10%" }}>
                                <div>
                                    <SiReact />
                                </div>
                            </IconContext.Provider>
                        </td>
                    </tr>
                    <tr id='row3'>
                        <td>
                        <IconContext.Provider value={{ color: "orange", className: "global-class-name", size:"10%" }}>
                                <div>
                                    <FaAws />
                                </div>
                            </IconContext.Provider>
                        </td>
                        <td>
                        <IconContext.Provider value={{ color: "blue", className: "global-class-name", size:"10%" }}>
                                <div>
                                    <SiMicrosoftazure />
                                </div>
                            </IconContext.Provider>
                        </td>
                        <td>
                        <IconContext.Provider value={{ color: "red", className: "global-class-name", size:"10%" }}>
                                <div>
                                    <SiLaravel />
                                </div>
                            </IconContext.Provider>
                        </td>
                        <td>
                        <IconContext.Provider value={{ className: "global-class-name", size:"10%" }}>
                                <div>
                                    <SiWordpress />
                                </div>
                            </IconContext.Provider>
                        </td>
                    </tr>
                    <tr id='row4'>
                        <td>
                        <IconContext.Provider value={{ className: "global-class-name", size:"10%" }}>
                                <div>
                                    <SiPhp />
                                </div>
                            </IconContext.Provider>
                        </td>
                        <td>
                        <IconContext.Provider value={{ color: "lightGreen", className: "global-class-name", size:"10%" }}>
                                <div>
                                    <SiSpringboot />
                                </div>
                            </IconContext.Provider>
                        </td>
                    </tr>
                    </tbody>
                </Table>
                </div>
                </div>
            </div>

            <div id='contact'>

                <div>
                    <div></div>
                    <div></div>
                </div>

            </div>
        </div>
        </>
    );
};
