import React from 'react';
import './services.css';
import uiuxPng from "./serviceIcons/uiux.png";
import consultPng from "./serviceIcons/consulting.png";
import devopsPng from "./serviceIcons/devops.png";
import gamePng from "./serviceIcons/gameDev.png";
import mobPng from "./serviceIcons/mobileDev.png";
import shopifyPng from "./serviceIcons/shopify.png";
import webPng from "./serviceIcons/webDev.png";
import wpPng from "./serviceIcons/wordPress.png";

export default function Services(params) {
  return (
    <>
      <section id='services'>
        <h2>Our Services</h2>
        <div className='services-container'>
          <div className='service'>
            <img src={uiuxPng} alt='UI/UX Design' />
            <h3>UI & UX Design</h3>
          </div>
          <div className='service'>
            <img src={wpPng} alt='WordPress Development' />
            <h3>WordPress Development</h3>
          </div>
          <div className='service'>
            <img src={shopifyPng} alt='Shopify Development' />
            <h3>Shopify Development</h3>
          </div>
          <div className='service'>
            <img src={mobPng} alt='Mobile Applications' />
            <h3>Mobile Applications</h3>
          </div>
          <div className='service'>
            <img src={webPng} alt='Web Applications' />
            <h3>Web Applications</h3>
          </div>
          <div className='service'>
            <img src={consultPng} alt='Consultancy Services' />
            <h3>Consultancy Services</h3>
          </div>
          <div className='service'>
            <img src={gamePng} alt='Games Development' />
            <h3>Games Development</h3>
          </div>
          <div className='service'>
            <img src={devopsPng} alt='Cloud & DevOps' />
            <h3>Cloud & DevOps</h3>
          </div>
        </div>
        <div className="cta">
          <button><a href='/contact'>Contact Us</a></button>
        </div>
      </section>
    </>
  );
}
