import React from 'react';
import './footer.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'font-awesome/css/font-awesome.min.css';

export default function Footer(params) {
    return(
        <>
        <div className="footer-clean">
        <footer>
            <div className="container">
                <div className="row justify-content-center">
                    {/* <div className="col-sm-4 col-md-3 item">
                        <h3>Services</h3>
                        <ul>
                            <li><a href="#">UI & UX Design</a></li>
                            <li><a href="#">WordPress Development</a></li>
                            <li><a href="#">Shopify Development</a></li>
                            <li><a href="#">Mobile Applications</a></li>
                            <li><a href="#">Web Applications</a></li>
                            <li><a href="#">Consultancy Services</a></li>
                            <li><a href="#">Games Development</a></li>
                            <li><a href="#">Cloud & DevOps</a></li>
                        </ul>
                    </div> */}
                    <div className="col-sm-4 col-md-3 item">
                        <h3>About</h3>
                        <ul>
                            <li><a href="/about">About Us</a></li>
                            <li><a href="/services">Services</a></li>
                            {/* <li><a href="#">Team</a></li>
                            <li><a href="#">Legacy</a></li> */}
                        </ul>
                    </div>
                    {/* <div className="col-sm-4 col-md-3 item">
                        <h3>Careers</h3>
                        <ul>
                            <li><a href="#">Job openings</a></li>
                            <li><a href="#">Employee success</a></li>
                            <li><a href="#">Benefits</a></li>
                        </ul>
                    </div> */}
                    <div className="col-lg-3 item social">
                        {/* <a href="#"><i className="icon fa fa-facebook"></i></a>
                        <a href="#"><i className="icon fa fa-twitter"></i></a>
                        <a href="#"><i className="icon fa fa-snapchat"></i></a> */}
                        <a href="https://www.instagram.com/faraztechnologyservices/"><i className="icon fa fa-instagram"></i></a>
                        {/* <p className="copyright">Faraz Technology Services © 2023</p> */}
                    </div>
                </div>
            </div>
            <p className="copyright" style={{textAlign:"center"}}>Faraz Technology Services Corp © 2023</p>
        </footer>
    </div>
        </>
    );
};
