import React, {useEffect,useState} from "react";
import './App.css';
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
  Link
} from "react-router-dom";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Home from './Home';
import Contact from './ContactUs';
import Services from './Services';
import About from "./About";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarText
} from 'reactstrap';
import Footer from "./Footer";
import ftslogo from "./FTS-logo.webp";

function App() {

  useEffect(()=>{
    document.title="FTS Corp."
  },[])

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  return (
    <Router>
    <div className="App">
      {/**navbar */}
    <Navbar color='light'  light expand='md'>
          
    <NavbarBrand className='btn' href='/'> <img src={ftslogo} style={{height:"60px", width:"90px"}} /> </NavbarBrand>
    <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
              <Nav navbar>
                <NavItem>
                    <NavLink className='btn' href='/'>Home</NavLink>
                </NavItem>
                {/* <NavItem>
                    <NavLink className='btn' href='/about' > About Us </NavLink>
                </NavItem> */}
                <NavItem>
                    <NavLink className='btn' href='/contact' > Contact Us </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
        </Navbar>
    </div>

    {/**switch and routes */}
    <Switch>
      <Route path='/contact' element={<Contact />} />
      <Route path='/services' element={<Services />} />
      <Route path='/' element={<Home />} />
      <Route path='/about' element={<About />} />
    </Switch>
    <Footer />
    </Router>
  );
}

export default App;
