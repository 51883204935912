import React from 'react';
import './about.css';

export default function About() {
return (
<div className="about">
<div className="container">
<h1>Faraz Technology Services</h1>
<p>Faraz Technology Services is a software consulting company that provides innovative solutions to businesses looking to leverage the latest technologies to enhance their operations and achieve their goals. Our team of experienced software developers, designers, and consultants has a passion for using technology to solve complex business problems, and we're dedicated to helping our clients succeed.</p>
<h2>Our Mission</h2>
<p>Our mission at Faraz Technology Services is to help businesses thrive by providing them with cutting-edge software solutions that help them streamline their operations, improve their efficiency, and drive growth. We believe that technology can be a powerful tool for businesses of all sizes, and we're committed to helping our clients leverage it to achieve their goals.</p>
<h2>Our Approach</h2>
<p>At Faraz Technology Services, we take a client-focused approach to software consulting. We work closely with our clients to understand their unique needs and challenges, and we tailor our solutions to meet their specific requirements. Our team is dedicated to delivering high-quality work that exceeds our clients' expectations, and we're committed to building long-term relationships based on trust, transparency, and mutual respect.</p>
<h2>Our Services</h2>
<p>We offer a wide range of software consulting services, including:</p>
<ul>
<li>UI & UX Design</li>
<li>WordPress Development</li>
<li>Shopify Development</li>
<li>Mobile Applications</li>
<li>Web Applications</li>
<li>Consultancy Services</li>
<li>Games Development</li>
<li>Cloud & DevOps</li>
</ul>
<p>If you're looking for a software consulting partner that can help you achieve your business goals, look no further than Faraz Technology Services. <a href='/contact'>Contact us</a> today to learn more about our services and how we can help your business succeed.</p>
</div>
</div>
);
}