import React, { Component,useState } from 'react';
import "./contactUs.css";
import emailjs from '@emailjs/browser';

export default function ContactUs(params) {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    function handleSubmit(e){
        e.preventDefault();

        var templateParams = {
            name:name,
            email:email,
            subject:subject,
            message:message
        };
    
        emailjs.send('service_sno7gno', 'template_hqtt2ae', templateParams, 'user_icjZIwthvlWqjV8GAMvTX')
          .then((result) => {
              console.log(result.text);
              alert("Message Sent! Thank you for contacting me, I will reply shortly.");
          }, (error) => {
              console.log(error.text);
              alert("Message cannot be sent at the moment! Please click on the email below to take you to the mail app. Sorry for the inconvenience!");
          });
          e.target.reset();
    }

    return(
        <>
        <div className="contact-us">
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="subject">Subject</label>
          <input
            type="text"
            id="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
        </div>
        <button type="submit">Submit</button>
      </form>
      <h4>OR</h4>
    <h5>Reach us at <a href="mailto:info@ftscorp.ca">INFO@FTSCORP.CA</a></h5>
    </div>
    
        </>
    );
};
